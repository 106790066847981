.info-table {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    line-height: 2;

    div {
        border-bottom: 1px solid #ccc;
    }

    .title {
        border-bottom: 2px solid #ccc;
        font-weight: 700;
    }
}