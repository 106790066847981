.nav-bar {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    min-height: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    &__brand {
        color: #777;
        padding: 15px 15px;
        font-size: 18px;
        line-height: 20px;
    }

    &-links {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: flex-start;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}

.nav-item {
    color: #555;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: none;

    @media (max-width: 768px) {
        line-height: 3;
    }

    &:hover {
        color: #333;
    }

    &.active {
        background-color: #e7e7e7;
    }
}

.language-switcher {
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    color: #555;

    span {
        padding: 5px;
        cursor: pointer;
    }
}