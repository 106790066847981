.scenario-input {
    &__fields {
        display: grid;

        grid-template-columns: 1fr;
        grid-gap: 10px;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1500) {
            grid-template-columns: repeat(4, 1fr);
        }

    }
}