.page {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
    padding: 10px;
    grid-template-rows: auto;
}

.grid-item {
    background-color: #e0e0e0;
    padding: 20px;
    border: 1px solid #ccc;
}

.small-12 {
    grid-column: span 12;
}

.medium-6 {

    @media (max-width: 1145px) {
        grid-column: span 12;
    }

    grid-column: span 6;
}

.medium-5 {
    grid-column: span 6;
}

.medium-4 {
    grid-column: span 4;
}

.chart-box {
    margin-bottom: 15px;
}