.cp-awc-table {

    border: 1px solid #ccc;
    border-bottom: none;

    &__row {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding: 0;
        border-bottom: 1px solid #ccc;


        div {
            grid-column: span 2;
            border-left: 1px solid #ccc;
            padding: 5px;

            position: relative;

            input {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: none;
            }

        }

        .first {
            grid-column: span 1;
            border-left: none;
        }
    }


}