.number-input,
.select-input,
.time-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 12px;
    }

    input {
        display: block;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        width: calc(100% - 24px);
    }

    select {
        @extend input;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        padding-left: 8px;
        width: 100%;
    }
}

.time-input {
    &__input-fields {
        display: flex;
        flex-direction: row;

        input {
            width: 50%;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:nth-child(2) {
                border-radius: 0;
            }
        }
    }

}