.scenario-result {
    border-top: 1px solid #ccc;
    grid-column: span 3;
    padding-top: 10px;
    display: flex;
    flex-direction: row;

    &__column {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
    }

    &__label {
        font-weight: 700;
        font-size: 12px;
    }

    &__value {
        font-size: 18px;
        padding-top: 5px;
    }
}